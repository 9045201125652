<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-0">
        <v-row class="justify-space-arround">
            <v-col cols="12" xl="6" class="mt-4">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('company.detailsOfTheResponsible')"
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            v-model="model.responsibleName"
                            :error-messages="errors.collect('responsibleName')"
                            data-vv-name="responsibleName"
                            v-validate="'required'"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        >
                        <template v-slot:label>
                            {{ $t('company.responsibleName.label') }} <v-icon x-small color="info" style="vertical-align: top">mdi-asterisk</v-icon>
                        </template>
                        </v-text-field>
                        <v-text-field
                            v-model="model.responsibleStreet"
                            :label="$t('company.street.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.responsiblePostcode"
                            :label="$t('company.postcode.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.responsibleCity"
                            :label="$t('company.city.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-autocomplete
                            v-model="model.responsibleCountryId"
                            :items="countryItems"
                            item-value="countryCode"
                            item-text="countryName"
                            :error-messages="errors.collect('responsibleCountry')"
                            data-vv-name="responsibleCountry"
                            v-validate="'required'"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        >
                            <template v-slot:label>
                                {{ $t('company.country.label') }} <v-icon x-small color="info" style="vertical-align: top">mdi-asterisk</v-icon>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-model="model.companyPhone"
                            :label="$t('company.phone.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.companyFax"
                            :label="$t('company.fax.label')"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.companyEmail"
                            :label="$t('company.email.label')"
                            spellcheck="true"
                            :error-messages="
                                errors.collect('companyEmail')
                            "
                            data-vv-name="companyEmail"
                            v-validate="'email'"
                            :data-vv-as="$t('invalidEmail')"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.companyWebsite"
                            :label="$t('company.website.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xl="6" class="mt-4">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('company.detailsOfTheRepresentativeOfTheResponsible')"
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            v-model="model.legalRepresentative"
                            :label="$t('company.legalRepresentative.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.headOfDataProcessing"
                            :label="$t('company.headOfDataProcessing.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.domesticRepresentative"
                            :label="$t('company.domesticRepresentative.label')"
                            :hint="$t('company.domesticRepresentative.hint')"
                            persistent-hint
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.euRepresentative"
                            :label="$t('company.euRepresentative.label')"
                            :hint="$t('company.euRepresentative.hint')"
                            persistent-hint
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xl="6">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('company.detailsOfTheDataPrivacyOfficer')"
                        ></v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerName"
                            :label="$t('company.dataPrivacyOfficerName.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerCompany"
                            :label="$t('company.dataPrivacyOfficerCompany.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerStreet"
                            :label="$t('company.street.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerPostcode"
                            :label="$t('company.postcode.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerCity"
                            :label="$t('company.city.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-autocomplete
                            v-model="model.dataPrivacyOfficerCountryId"
                            :items="countryItems"
                            :label="$t('company.country.label')"
                            item-value="countryCode"
                            item-text="countryName"
                            spellcheck="true"
                            :error-messages="errors.collect('dataPrivacyOfficerCountryId')"
                            data-vv-name="dataPrivacyOfficerCountryId"
                            v-validate="'required'"
                            @input="$emit('modified', $event)"
                        >
                            <template v-slot:label>
                                {{ $t('company.country.label') }} <v-icon x-small color="info" style="vertical-align: top">mdi-asterisk</v-icon>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerPhone"
                            :label="$t('company.phone.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerFax"
                            :label="$t('company.fax.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerEmail"
                            :label="$t('company.email.label')"
                            spellcheck="true"
                            :error-messages="
                                errors.collect('dataPrivacyOfficerEmail')
                            "
                            data-vv-name="dataPrivacyOfficerEmail"
                            v-validate="'email'"
                            :data-vv-as="$t('invalidEmail')"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataPrivacyOfficerWebsite"
                            :label="$t('company.website.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xl="6">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('company.detailsOfTheDataProtectionAuthority')"
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityName"
                            :label="$t('company.nameOfAuthority.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="
                                model.dataProtectionAuthorityPersonResponsible
                            "
                            :label="$t('company.contactPerson.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityStreet"
                            :label="$t('company.street.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityPostcode"
                            :label="$t('company.postcode.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityCity"
                            :label="$t('company.city.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-autocomplete
                            v-model="model.dataProtectionAuthorityCountryId"
                            :items="countryItems"
                            :label="$t('company.country.label')"
                            item-value="countryCode"
                            item-text="countryName"
                            spellcheck="true"
                            :error-messages="errors.collect('dataProtectionAuthorityCountryId')"
                            data-vv-name="dataProtectionAuthorityCountryId"
                            v-validate="'required'"
                            @input="$emit('modified', $event)"
                        >
                            <template v-slot:label>
                                {{ $t('company.country.label') }} <v-icon x-small color="info" style="vertical-align: top">mdi-asterisk</v-icon>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityPhone"
                            :label="$t('company.phone.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityFax"
                            :label="$t('company.fax.label')"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityEmail"
                            :label="$t('company.email.label')"
                            spellcheck="true"
                            :error-messages="
                                errors.collect('dataProtectionAuthorityEmail')
                            "
                            data-vv-name="dataProtectionAuthorityEmail"
                            v-validate="'email'"
                            :data-vv-as="$t('invalidEmail')"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.dataProtectionAuthorityWebsite"
                            :label="$t('company.website.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SettingsCompanyListEditorMask',
    inject: {
        $validator: '$validator',
    },
    props: {
        countryItems: Array,
        value: Object,
    },
    i18n: {
        messages: {
            en: require('@/locales/Company/en.json'),
            de: require('@/locales/Company/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
        };
    },
};
</script>
